/* eslint-disable @typescript-eslint/no-unused-vars */
import Config from "@domain/entities/config-entity";
import { ConfigOptionsType, IGetConfig, IGetConfigOptions, IQueryConfig } from "@domain/interfaces/config-interface";
import ConfigRepository from "@domain/repositories/config-repository";
import { injectable } from "inversify";

@injectable()
export default class ConfigMockupRepository implements ConfigRepository {
  private item = {
    id: "123456",
    app_current_version: {
      ios: "1.0.9",
      android: "1.0.9",
    },
    test_list: [
      {
        phone: "0971965596",
        createdBy: "superadmin",
        time: "2023-10-17T09:24:30.997+0000",
      },
    ],
    bookingMeal: {
      isLockTime: false,
      lockTime: {
        hour: 13,
        minutes: 0,
      },
      scanUsers: ["9805", "1029"],
      scanUsersInfo: [
        {
          employee_id: "9805",
          name: "ĐỖ THỊ KIM XUYẾN",
          avatar: "https://cm-media.ngocdunggroup.com.vn/theadvance/d972e58b-cf62-4d2d-a514-f9b26232cf9c",
        },
        {
          employee_id: "1029",
          name: "NGUYỄN MINH TRÍ",
          avatar: "https://cm-media.ngocdunggroup.com.vn/theadvance/d972e58b-cf62-4d2d-a514-f9b26232cf9c",
        },
      ],
    },
    createdBy: "admin",
    updatedBy: "admin",
    createdAt: "",
    updatedAt: "",
  };

  async getConfig (query: IQueryConfig): Promise<IGetConfig> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: this.item,
    };
  }
  async updateConfig (body: Partial<Config>): Promise<IGetConfig> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: this.item,
    };
  }

  async getOptions (type: ConfigOptionsType): Promise<IGetConfigOptions> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: [{ label: "", value: "" }],
    };
  }
}
