/* eslint-disable @typescript-eslint/no-unused-vars */
import { IGetRuleDetail, IGetRuleList, IGetRuleSubjectList } from "@domain/interfaces/rule-interface";
import RuleRepository from "@domain/repositories/rule-repository";
import { injectable } from "inversify";

@injectable()
export default class RuleMockupRepository implements RuleRepository {
  private item = {
    id: "123456",
    name: "",
    actions: "",
    subject: "",
    createdBy: "admin",
    updatedBy: "admin",
    createdAt: "",
    updatedAt: "",
  };

  async getList (query: any): Promise<IGetRuleList> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: { items: [], total: 0, limit: 10 },
    };
  }

  async create (body: any): Promise<IGetRuleDetail> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: this.item,
    };
  }

  async update (id: string, body: any): Promise<IGetRuleDetail> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: this.item,
    };
  }

  async remove (id: string): Promise<IGetRuleDetail> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: this.item,
    };
  }
  async getSubject (): Promise<IGetRuleSubjectList> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: {
        items: [
          {
            value: "all",
            text: "all",
          },
          {
            value: "account",
            text: "account",
          },
          {
            value: "app-version",
            text: "appVersion",
          },
          {
            value: "checkin",
            text: "checkIn",
          },
          {
            value: "config",
            text: "config",
          },
          {
            value: "food-booking-group",
            text: "foodBookingGroup",
          },
          {
            value: "food-logs",
            text: "foodLogs",
          },
          {
            value: "home-menu",
            text: "homeMenu",
          },
          {
            value: "news",
            text: "news",
          },
          {
            value: "news-category",
            text: "newsCategory",
          },
          {
            value: "notification",
            text: "notification",
          },
          {
            value: "otp",
            text: "otp",
          },
          {
            value: "roles",
            text: "roles",
          },
          {
            value: "rules",
            text: "rules",
          },
          {
            value: "segments",
            text: "segments",
          },
          {
            value: "support",
            text: "support",
          },
          {
            value: "tasks",
            text: "tasks",
          },
          {
            value: "user-deleted",
            text: "userDeleted",
          },
          {
            value: "user",
            text: "user",
          },
          {
            value: "workflow-logs",
            text: "workflowLogs",
          },
          {
            value: "province",
            text: "province",
          },
          {
            value: "district",
            text: "district",
          },
          {
            value: "ward",
            text: "ward",
          },
          {
            value: "job",
            text: "job",
          },
        ],
      },
    };
  }
}
