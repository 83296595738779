import { DEFAULT_LIMIT } from "@common/constant";
import { IGetCheckInList } from "@domain/interfaces/check-in-interface";
import CheckInRepository from "@domain/repositories/check-in-repository";
import ApiService from "@infrastructure/network/api";
import { injectable } from "inversify";

@injectable()
export default class CheckInApiRepository implements CheckInRepository {
  private apiService = new ApiService();

  async getList (query: any): Promise<IGetCheckInList> {
    const result = await this.apiService.get("/check-in", query);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: {
        items: result.data?.items || [],
        total: result.data?.total || 0,
        limit: result.data?.limit || DEFAULT_LIMIT,
      },
    };
  }

  async resetCheckInDevice (employeeId: string): Promise<any> {
    const result = await this.apiService.put(`/check-in/device/${employeeId}`, {});

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }
}
