export default class Account {
  id: string;
  username: string;
  name: string;
  email: string;
  phone: string;
  status: string;
  rules: string[];
  role: string;
  createdBy: string;
  updatedBy: string;
  createdAt: string;
  updatedAt: string;

  constructor (data?: any) {
    this.username = data?.username;
    this.id = data?.id;
    this.name = data?.name;
    this.email = data?.email;
    this.phone = data?.phone;
    this.status = data?.status;
    this.role = data?.role;
    this.rules = data?.rules;
    this.createdBy = data?.createdBy;
    this.updatedBy = data?.updatedBy;
    this.createdAt = data?.createdAt;
    this.updatedAt = data?.updatedAt;
  }
}
