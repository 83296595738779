/* eslint-disable @typescript-eslint/no-unused-vars */
import BookingMealGroup from "@domain/entities/booking-meal-group-entity";

export default {
  getList: (query?: any) => ({
    status: 200,
    code: 1,
    message: "Thành công",
    data: {
      items: [
        {
          id: "123456",
          name: "",
          createdBy: "admin",
          updatedBy: "admin",
          status: "active",
          createdAt: "",
          updatedAt: "",
        },
      ] as BookingMealGroup[],
      total: 1,
      limit: 10,
    },
  }),
  create: (body: any) => ({
    status: 200,
    code: 1,
    message: "Thành công",
    data: <BookingMealGroup>{
      id: "123456",
      name: "",
      employees: [],
      createdBy: "admin",
      updatedBy: "admin",
      status: "active",
      createdAt: "",
      updatedAt: "",
    },
  }),
  update: (id: string, body: any) => ({
    status: 200,
    code: 1,
    message: "Thành công",
    data: <BookingMealGroup>{
      id: "123456",
      name: "",
      createdBy: "admin",
      updatedBy: "admin",
      status: "active",
      createdAt: "",
      updatedAt: "",
    },
  }),
  remove: (id: string) => ({
    status: 200,
    code: 1,
    message: "Thành công",
    data: <BookingMealGroup>{
      id: "123456",
      name: "",
      createdBy: "admin",
      updatedBy: "admin",
      status: "active",
      createdAt: "",
      updatedAt: "",
    },
  }),
};
