import { IGetCustomerRecordDetail, IGetCustomerRecordList, IQueryCustomerRecord } from "@domain/interfaces/customer-record-interface";
import CustomerRecordRepository from "@domain/repositories/customer-record-repository";
import ApiService from "@infrastructure/network/api";
import { injectable } from "inversify";

@injectable()
export default class CustomerRecordApiRepository implements CustomerRecordRepository {
  private apiService = new ApiService();

  async getList (query: IQueryCustomerRecord): Promise<IGetCustomerRecordList> {
    const result = await this.apiService.get("/cm-media/record", query);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async sendRecordToGroup (id: string): Promise<IGetCustomerRecordDetail> {
    const result = await this.apiService.post(`/cm-media/record/${id}`, {});

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }
}
