/* eslint-disable @typescript-eslint/no-unused-vars */
import { InternalError } from "@common/kernel/custom-error";
import ErrorCode from "@common/kernel/error-code";
import Config from "@domain/entities/config-entity";
import { ConfigOptionsType, IGetConfig, IGetConfigOptions, IQueryConfig } from "@domain/interfaces/config-interface";

export default abstract class ConfigRepository {
  async getConfig (query?: IQueryConfig): Promise<IGetConfig> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async updateConfig (body: Partial<Config>): Promise<IGetConfig> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async getOptions (type: ConfigOptionsType): Promise<IGetConfigOptions> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}
