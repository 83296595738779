/* eslint-disable @typescript-eslint/no-unused-vars */
import CallAgent from "@domain/entities/call-agent-entity";
import { IGetCallAgentAccessToken, IGetCallAgentDetail, IGetCallAgentList, IQueryCallAgent } from "@domain/interfaces/call-agent-interface";
import CallAgentRepository from "@domain/repositories/call-agent-repository";
import callAgentMockup from "@infrastructure/mockup/call-agent-mockup";
import { injectable } from "inversify";

@injectable()
export default class CallAgentMockupRepository implements CallAgentRepository {
  async getList (query: IQueryCallAgent): Promise<IGetCallAgentList> {
    const result = callAgentMockup.getList(query);

    return {
      isSuccess: result.code === 1,
      message: result.message,
      data: {
        items: result.data.items,
        total: result.data.total,
        limit: result.data.limit,
      },
    };
  }

  async create (body: CallAgent): Promise<IGetCallAgentDetail> {
    const result = callAgentMockup.create(body);

    return {
      isSuccess: result.code === 1,
      message: result.message,
      data: result.data,
    };
  }

  async update (id: string, body: Partial<CallAgent>): Promise<IGetCallAgentDetail> {
    const result = callAgentMockup.update(id, body);

    return {
      isSuccess: result.code === 1,
      message: result.message,
      data: result.data,
    };
  }

  async remove (id: string): Promise<IGetCallAgentDetail> {
    const result = callAgentMockup.remove(id);

    return {
      isSuccess: result.code === 1,
      message: result.message,
      data: result.data,
    };
  }

  async getAccessToken (userId: string): Promise<IGetCallAgentAccessToken> {
    const result = callAgentMockup.getAccessToken(userId);

    return {
      isSuccess: result.code === 1,
      message: result.message,
      data: result.data,
    };
  }
}
