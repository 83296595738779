/* eslint-disable @typescript-eslint/no-unused-vars */
import ErrorCode from "@common/kernel/error-code";

import { InternalError } from "@common/kernel/custom-error";
import {
  ICreateBookingMeal,
  ICreateBookingMealBody,
  IExportBookingMeal,
  IGetBookingMealDetail,
  IGetBookingMealList,
  IQueryBookingMeal
} from "@domain/interfaces/booking-meal-interface";

export default abstract class BookingMealRepository {
  async getList (query?: IQueryBookingMeal): Promise<IGetBookingMealList> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async create (data: ICreateBookingMealBody): Promise<ICreateBookingMeal> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async remove (id: string): Promise<IGetBookingMealDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async export (body: IExportBookingMeal): Promise<void> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}
