import crypto from "crypto-js";

function sha256 (data: any) {
  return crypto.SHA256(data).toString();
}

function hmacSha256 (hashData: any, hashKey: string) {
  return crypto.HmacSHA256(hashData, hashKey).toString();
}

export default {
  sha256,
  hmacSha256,
};
