import crypto from "@common/utils/crypto";
import repository from "@config/repository";
import AccountRepository from "@domain/repositories/account-repository";

export default async function changePasswordUseCase (id: string, values: { oldPassword: string; newPassword: string }) {
  const accountRepository = repository.get<AccountRepository>(AccountRepository);

  return await accountRepository.changePassword(id, {
    oldPassword: crypto.sha256(values.oldPassword),
    newPassword: crypto.sha256(values.newPassword),
  });
}
