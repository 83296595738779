import { DEFAULT_LIMIT } from "@common/constant";
import BookingMealGroup from "@domain/entities/booking-meal-group-entity";
import {
  IGetBookingGroupEmployee,
  IGetBookingMealGroupDetail,
  IGetBookingMealGroupList,
  IQueryBookingMealGroup
} from "@domain/interfaces/booking-meal-group-interface";
import BookingMealGroupRepository from "@domain/repositories/booking-meal-group-repository";
import ApiService from "@infrastructure/network/api";
import { injectable } from "inversify";

@injectable()
export default class BookingMealGroupApiRepository implements BookingMealGroupRepository {
  private api = new ApiService();

  async getList (query: IQueryBookingMealGroup): Promise<IGetBookingMealGroupList> {
    const result = await this.api.get("/booking-meal-group", query);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: {
        items: result.data?.items || [],
        total: result.data?.total || 0,
        limit: result.data?.limit || DEFAULT_LIMIT,
      },
    };
  }

  async create (body: BookingMealGroup): Promise<IGetBookingMealGroupDetail> {
    const result = await this.api.post("/booking-meal-group", body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async update (id: string, body: Partial<BookingMealGroup>): Promise<IGetBookingMealGroupDetail> {
    const result = await this.api.put(`/booking-meal-group/${id}`, body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async remove (id: string): Promise<IGetBookingMealGroupDetail> {
    const result = await this.api.remove(`/booking-meal-group/${id}`);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async getEmployee (id: string): Promise<IGetBookingGroupEmployee> {
    const result = await this.api.get(`/booking-meal-group/${id}/employee`, {});

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async updateEmployee (id: string, action: "add" | "remove", body: { employees: string[] }): Promise<IGetBookingGroupEmployee> {
    const result = await this.api.put(`/booking-meal-group/${id}/employee/${action}`, body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }
}
