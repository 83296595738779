/* eslint-disable @typescript-eslint/no-unused-vars */
import ErrorCode from "@common/kernel/error-code";

import { InternalError } from "@common/kernel/custom-error";
import { IGetCheckInList, IQueryCheckIn } from "@domain/interfaces/check-in-interface";

export default abstract class CheckInRepository {
  async getList (query?: IQueryCheckIn): Promise<IGetCheckInList> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async resetCheckInDevice (employeeId: string): Promise<any> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}
