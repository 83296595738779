import { IUploadImageToStatic } from "@domain/interfaces/upload-interface";
import UploadRepository from "@domain/repositories/upload-repository";
import axios from "axios";
import { injectable } from "inversify";

@injectable()
export default class UploadRepositoryApiRepository implements UploadRepository {
  private UPLOAD_STATIC = "https://upload.ngocdunggroup.com.vn/upload/images?product=ZEMA_APP&token=de4fefbc0f4602388af2";

  async uploadImageToStatic (files: Array<File>): Promise<IUploadImageToStatic> {
    const config = { headers: { "Content-Type": "multipart/form-data" } };

    const formData = new FormData();
    files.forEach((file) => {
      formData.append("file1", file, file.name);
    });

    const response = await axios.post(this.UPLOAD_STATIC, formData, config);

    if (response.data) return response.data;

    return {
      error_code: 0,
      message: "",
      status: 0,
      data: [],
    };
  }
}
