/* eslint-disable @typescript-eslint/no-unused-vars */
import { IGetRoleDetail, IGetRoleList } from "@domain/interfaces/role-interface";
import RoleRepository from "@domain/repositories/role-repository";
import { injectable } from "inversify";

@injectable()
export default class RoleMockupRepository implements RoleRepository {
  private item = {
    id: "123456",
    name: "test",
    rules: [],

    createdBy: "admin",
    updatedBy: "admin",
    createdAt: "",
    updatedAt: "",
  };

  async getList (query: any): Promise<IGetRoleList> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: { items: [], total: 0, limit: 10 },
    };
  }

  async create (body: any): Promise<IGetRoleDetail> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: this.item,
    };
  }

  async update (id: string, body: any): Promise<IGetRoleDetail> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: this.item,
    };
  }

  async remove (id: string): Promise<IGetRoleDetail> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: this.item,
    };
  }
}
