import { routesName } from "@common/route";
import { useAccountStore } from "@presentation/store/account-store";
import { paramCase, pascalCase } from "change-case-all";
import { RouteRecordRaw, createRouter, createWebHistory } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: routesName.login,
    meta: { authRequired: false },
    component: () => import("@interfaces/views/LoginView.vue"),
    beforeEnter: (to, from, next) => {
      const userStore = useAccountStore();

      if (!userStore.isLogin) return next();

      return next({ name: routesName.admin.home });
    },
  },
  {
    path: "/",
    name: routesName.root,
    component: () => import("@interfaces/views/AdminView.vue"),
    beforeEnter: async (to, from, next) => {
      const accountStore = useAccountStore();

      if (!accountStore.isLogin) return next({ name: routesName.login });

      return next();
    },
    children: Object.keys(routesName.admin).map((name) => {
      return {
        name,
        path: `/${paramCase(name)}`,
        component: () => import(`@interfaces/views/admin/${pascalCase(name)}View.vue`),
        beforeEnter: (to, from, next) => {
          const accountStore = useAccountStore();
          accountStore.adminAccessRoutes.find((route) => route === to.name) ? next() : next({ name: routesName.notFound });
        },
      };
    }),
  },
  { path: "/:pathMatch(.*)*", name: "notFound", component: () => import("@interfaces/views/NotFound.vue") },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
