/* eslint-disable @typescript-eslint/no-unused-vars */
import { IGetCustomerRecordDetail, IGetCustomerRecordList, IQueryCustomerRecord } from "@domain/interfaces/customer-record-interface";
import CustomerRecordRepository from "@domain/repositories/customer-record-repository";
import { injectable } from "inversify";

@injectable()
export default class CustomerRecordMockupRepository implements CustomerRecordRepository {
  async getList (query: IQueryCustomerRecord): Promise<IGetCustomerRecordList> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: { items: [], total: 0, limit: 10 },
    };
  }

  async sendRecordToGroup (id: string): Promise<IGetCustomerRecordDetail> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: {
        id: "",
        filename: "",
        link: "",
        mimetype: "",
        branchId: "",
        roomCode: "",
        customerId: "",
        createdBy: "",
        updatedBy: "",
        createdAt: "",
        updatedAt: "",
        org: "ND",
        customerName: "",
        customerCode: "",
        employeeId: "",
        employeeName: "",
      },
    };
  }
}
