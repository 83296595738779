/* eslint-disable @typescript-eslint/no-unused-vars */
import { InternalError } from "@common/kernel/custom-error";
import ErrorCode from "@common/kernel/error-code";
import TestPhone from "@domain/entities/test-phone-entity";
import { IGetTestPhoneDetail, IGetTestPhoneList, IQueryTestPhone } from "@domain/interfaces/test-phone-interface";

export default abstract class TestPhoneRepository {
  async getList (query?: IQueryTestPhone): Promise<IGetTestPhoneList> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async create (data: TestPhone): Promise<IGetTestPhoneDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async remove (phone: string): Promise<IGetTestPhoneDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}
