import Config from "@domain/entities/config-entity";
import { ConfigOptionsType, IGetConfig, IGetConfigOptions, IQueryConfig } from "@domain/interfaces/config-interface";
import ConfigRepository from "@domain/repositories/config-repository";
import ApiService from "@infrastructure/network/api";
import { injectable } from "inversify";

@injectable()
export default class ConfigApiRepository implements ConfigRepository {
  private apiService = new ApiService();

  async getConfig (query: IQueryConfig): Promise<IGetConfig> {
    const result = await this.apiService.get("/configurations", query);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async updateConfig (body: Partial<Config>): Promise<IGetConfig> {
    const result = await this.apiService.put("/configurations/", body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async getOptions (type: ConfigOptionsType): Promise<IGetConfigOptions> {
    const result = await this.apiService.get(`/configurations/options/${type}`, {});

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }
}
