/* eslint-disable @typescript-eslint/no-unused-vars */
import { InternalError } from "@common/kernel/custom-error";
import ErrorCode from "@common/kernel/error-code";
import CallQueue from "@domain/entities/call-queue-entity";
import { IGetCallQueueDetail, IGetCallQueueList, IQueryCallQueue } from "@domain/interfaces/call-queue-interface";

export default abstract class CallQueueRepository {
  async getList (query?: IQueryCallQueue): Promise<IGetCallQueueList> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async create (data: CallQueue): Promise<IGetCallQueueDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async update (id: string, body: Partial<CallQueue>): Promise<IGetCallQueueDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async remove (id: string): Promise<IGetCallQueueDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}
