import { DEFAULT_LIMIT } from "@common/constant";
import { IGetRuleDetail, IGetRuleList, IGetRuleSubjectList, IQueryRule } from "@domain/interfaces/rule-interface";
import RuleRepository from "@domain/repositories/rule-repository";
import ApiService from "@infrastructure/network/api";
import { injectable } from "inversify";

@injectable()
export default class RuleApiRepository implements RuleRepository {
  private apiService = new ApiService();

  async getList (query: IQueryRule): Promise<IGetRuleList> {
    const result = await this.apiService.get("/rules", query);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: {
        items: result.data?.items || [],
        total: result.data?.total || 0,
        limit: result.data?.limit || DEFAULT_LIMIT,
      },
    };
  }

  async create (body: any): Promise<IGetRuleDetail> {
    const result = await this.apiService.post("/rules", body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async update (id: string, body: any): Promise<IGetRuleDetail> {
    const result = await this.apiService.put(`/rules/${id}`, body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async remove (id: string): Promise<IGetRuleDetail> {
    const result = await this.apiService.remove(`/rules/${id}`);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async getSubject (): Promise<IGetRuleSubjectList> {
    const result = await this.apiService.get("/rules/subject", {});

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: {
        items: result.data?.items || [],
      },
    };
  }
}
