/* eslint-disable @typescript-eslint/no-unused-vars */
import { InternalError } from "@common/kernel/custom-error";
import ErrorCode from "@common/kernel/error-code";
import BookingMealGroup from "@domain/entities/booking-meal-group-entity";
import {
  IGetBookingGroupEmployee,
  IGetBookingMealGroupDetail,
  IGetBookingMealGroupList,
  IQueryBookingMealGroup
} from "@domain/interfaces/booking-meal-group-interface";
import BookingMealGroupRepository from "@domain/repositories/booking-meal-group-repository";
import bookingMealGroupMockup from "@infrastructure/mockup/booking-meal-group-mockup";
import { injectable } from "inversify";

@injectable()
export default class BookingMealGroupMockupRepository implements BookingMealGroupRepository {
  async getList (query: IQueryBookingMealGroup): Promise<IGetBookingMealGroupList> {
    const result = bookingMealGroupMockup.getList(query);

    return {
      isSuccess: result.code === 1,
      message: result.message,
      data: {
        items: result.data.items,
        total: result.data.total,
        limit: result.data.limit,
      },
    };
  }

  async create (body: BookingMealGroup): Promise<IGetBookingMealGroupDetail> {
    const result = bookingMealGroupMockup.create(body);

    return {
      isSuccess: result.code === 1,
      message: result.message,
      data: result.data,
    };
  }

  async update (id: string, body: Partial<BookingMealGroup>): Promise<IGetBookingMealGroupDetail> {
    const result = bookingMealGroupMockup.update(id, body);

    return {
      isSuccess: result.code === 1,
      message: result.message,
      data: result.data,
    };
  }

  async remove (id: string): Promise<IGetBookingMealGroupDetail> {
    const result = bookingMealGroupMockup.remove(id);

    return {
      isSuccess: result.code === 1,
      message: result.message,
      data: result.data,
    };
  }

  async getEmployee (id: string): Promise<IGetBookingGroupEmployee> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async updateEmployee (id: string, action: "add" | "remove", body: { employees: string[] }): Promise<IGetBookingGroupEmployee> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}
