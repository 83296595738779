/* eslint-disable @typescript-eslint/no-unused-vars */
import { InternalError } from "@common/kernel/custom-error";
import ErrorCode from "@common/kernel/error-code";
import { IUploadImageToStatic } from "@domain/interfaces/upload-interface";

export default abstract class UploadRepository {
  async uploadImageToStatic (files: Array<File>): Promise<IUploadImageToStatic> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}
