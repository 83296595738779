import { DEFAULT_LIMIT } from "@common/constant";
import AppVersionRepository from "@domain/repositories/app-version-repository";
import ApiService from "@infrastructure/network/api";
import { injectable } from "inversify";

@injectable()
export default class AppVersionApiRepository implements AppVersionRepository {
  private apiService = new ApiService();

  async getList (query: any) {
    const result = await this.apiService.get("/app-version", query);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: {
        items: result.data?.items || [],
        total: result.data?.total || 0,
        limit: result.data?.limit || DEFAULT_LIMIT,
      },
    };
  }

  async create (body: any) {
    const result = await this.apiService.post("/app-version", body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async update (id: string, body: any) {
    const result = await this.apiService.put(`/app-version/${id}`, body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async remove (id: string) {
    const result = await this.apiService.remove(`/app-version/${id}`);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }
}
