import { DEFAULT_LIMIT } from "@common/constant";
import CallAgent from "@domain/entities/call-agent-entity";
import { IGetCallAgentAccessToken, IGetCallAgentDetail, IGetCallAgentList, IQueryCallAgent } from "@domain/interfaces/call-agent-interface";
import CallAgentRepository from "@domain/repositories/call-agent-repository";
import CallApiService from "@infrastructure/network/call-api";
import { injectable } from "inversify";

@injectable()
export default class CallAgentApiRepository implements CallAgentRepository {
  private api = new CallApiService();

  async getList (query: IQueryCallAgent): Promise<IGetCallAgentList> {
    const result = await this.api.get("/users", query);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: {
        items: result.data?.items || [],
        total: result.data?.total || 0,
        limit: result.data?.limit || DEFAULT_LIMIT,
      },
    };
  }

  async create (body: CallAgent): Promise<IGetCallAgentDetail> {
    const result = await this.api.post("/users", body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async update (id: string, body: Partial<CallAgent>): Promise<IGetCallAgentDetail> {
    const result = await this.api.put(`/users/${id}`, body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async remove (id: string): Promise<IGetCallAgentDetail> {
    const result = await this.api.remove(`/users/${id}`);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async getAccessToken (userId: string): Promise<IGetCallAgentAccessToken> {
    const result = await this.api.get(`/users/${userId}/token`, {});

    return {
      isSuccess: result.code === 1,
      message: result.message,
      data: result.data,
    };
  }
}
