const SUCCESS_CODE = {
  SUCCESS: { code: 1, sysMessage: "SUCCESS", message: "Thành công" },
};

const SESSION_CODE = {
  SESSION_TIMEOUT: {
    code: -15,
    sysMessage: "SESSION_TIMEOUT",
    message: "Đăng nhập hết hạn",
  },
};

const SYSTEM_CODE = {
  SYSTEM_ERROR: {
    code: -900,
    sysMessage: "SYSTEM_ERROR",
    message: "Có lỗi xảy ra, vui lòng thử lại sau!",
  },
  ERR_METHOD_NOT_IMPLEMENTED: {
    code: -902,
    sysMessage: "ERR_METHOD_NOT_IMPLEMENTED",
    message: "Thao tác chưa khả dụng",
  },
  FIREBASE_ERROR: {
    code: -903,
    sysMessage: "FIREBASE_ERROR",
    message: "Firebase: có lỗi xảy ra",
  },
};

const ErrorCode = {
  ...SUCCESS_CODE,
  ...SESSION_CODE,
  ...SYSTEM_CODE,
};

export const API_SYS_ERROR = {
  status: 0,
  code: SYSTEM_CODE.SYSTEM_ERROR.code,
  message: SYSTEM_CODE.SYSTEM_ERROR.message,
  data: {},
};

export const API_APP_ERROR = {
  status: 0,
  code: -1,
  message: "Có lỗi, vui lòng thử lại sau!",
  data: {},
};

export default ErrorCode;
