/* eslint-disable @typescript-eslint/no-unused-vars */
import { InternalError } from "@common/kernel/custom-error";
import ErrorCode from "@common/kernel/error-code";
import Role from "@domain/entities/role-entity";
import { IGetRoleDetail, IGetRoleList } from "@domain/interfaces/role-interface";

export default abstract class RoleRepository {
  async getList (query: any): Promise<IGetRoleList> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
  async create (data: Role): Promise<IGetRoleDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
  async update (id: string, body: any): Promise<IGetRoleDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async remove (query: any): Promise<IGetRoleDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}
