/* eslint-disable @typescript-eslint/no-unused-vars */
import { InternalError } from "@common/kernel/custom-error";
import ErrorCode from "@common/kernel/error-code";
import Account from "@domain/entities/account-entity";
import { IApiAccountLogin, IDetailAccount, IGetAccountList, IGetLoginAccountDetail } from "@domain/interfaces/account-interface";

export default abstract class AccountRepository {
  async getList (query: any): Promise<IGetAccountList> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
  async create (data: Account): Promise<IDetailAccount> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
  async update (id: string, body: any): Promise<IDetailAccount> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async remove (query: any): Promise<IDetailAccount> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
  async updateDeviceInfo (query: any): Promise<IDetailAccount> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async login (body: any): Promise<IApiAccountLogin> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async getLoginAccountDetail (query: any): Promise<IGetLoginAccountDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async logout (id: string): Promise<IDetailAccount> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async changePassword (id: string, body: any): Promise<IDetailAccount> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async forgotPassword (body: any): Promise<IDetailAccount> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async resetPassword (body: any): Promise<IDetailAccount> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async uploadAvatar (body: any): Promise<IDetailAccount> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}
