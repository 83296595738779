import { routesName } from "@common/route";
import { ACCOUNT_ROLE } from "@domain/constant/role-constant";
import { ACCOUNT_RULE, RULE_ACTION } from "@domain/constant/rule-constant";
import { AccountMenu, getAccountMenus, menuObject, superAdminMenu } from "@domain/helpers/admin-menu-helper";
import { defineStore } from "pinia";

import Account from "@domain/entities/account-entity";
import Rule from "@domain/entities/rule-entity";

export const useAdminMenuStore = defineStore("adminMenu", {
  state: () => ({
    menus: [] as any,
  }),
  persist: true,
  actions: {
    setAccountMenu (loginUser?: Account, rules?: Rule[]) {
      const routeList = Object.values(routesName.admin);

      if (!loginUser || !loginUser.id || !rules) {
        this.menus = [];

        return;
      }

      if (loginUser.role === ACCOUNT_ROLE.superadmin || loginUser.rules.includes(ACCOUNT_RULE.readAll)) {
        return (this.menus = getAccountMenus(superAdminMenu));
      }

      // get account menu by rule
      const accountMenu: AccountMenu[] = [];

      rules.forEach((rule) => {
        if (accountMenu.find((menu) => menu.name === rule.subject)) return;

        if (rule.actions === RULE_ACTION.read && routeList.find((routeName) => routeName === rule.subject)) {
          accountMenu.push(menuObject(rule.subject));
        }
      });

      this.menus = getAccountMenus(accountMenu);
    },
  },
  getters: {
    menu: (state) => state.menus,
  },
  logger: false,
});
