/* eslint-disable @typescript-eslint/no-unused-vars */
export default {
  getList: (query?: any) => ({
    status: 200,
    code: 1,
    message: "Thành công",
    data: {
      items: [
        {
          username: "xuyendtk",
          id: "123456",
          name: "Xuyen Xuyen",
          email: "admin@gmail.com",
          phone: "0971965596",
          createdBy: "admin",
          updatedBy: "admin",
          status: "active",
          role: "superadmin",
          rules: [],
          createdAt: "",
          updatedAt: "",
        },
      ],
      total: 1,
      limit: 10,
    },
  }),
  create: (body: any) => ({
    status: 200,
    code: 1,
    message: "Thành công",
    data: {
      username: "xuyendtk",
      id: "123456",
      name: "Xuyen Xuyen",
      email: "admin@gmail.com",
      phone: "0971965596",
      createdBy: "admin",
      updatedBy: "admin",
      status: "active",
      role: "superadmin",
      rules: [],
      createdAt: "",
      updatedAt: "",
    },
  }),
  update: (id: string, body: any) => ({
    status: 200,
    code: 1,
    message: "Thành công",
    data: {
      username: "xuyendtk",
      id: id,
      name: "Xuyen Xuyen",
      email: "admin@gmail.com",
      phone: "0971965596",
      createdBy: "admin",
      updatedBy: "admin",
      status: "active",
      role: "superadmin",
      rules: [],
      createdAt: "",
      updatedAt: "",
    },
  }),
  remove: (id: string) => ({
    status: 200,
    code: 1,
    message: "Thành công",
    data: {
      username: "xuyendtk",
      id: id,
      name: "Xuyen Xuyen",
      email: "admin@gmail.com",
      phone: "0971965596",
      createdBy: "admin",
      updatedBy: "admin",
      status: "active",
      role: "superadmin",
      rules: [],
      createdAt: "",
      updatedAt: "",
    },
  }),
  login: (body: { username: string; password: string }) => ({
    status: 200,
    code: 1,
    message: "Thành công",
    data: {
      user: {
        username: body.username,
        id: "123456",
        name: "Xuyen Xuyen",
        email: "admin@gmail.com",
        phone: "0971965596",
        createdBy: "admin",
        updatedBy: "admin",
        status: "active",
        role: "superadmin",
        rules: [],
        createdAt: "",
        updatedAt: "",
      },
      token: "123456",
    },
  }),
  getLoginAccountDetail: (query: any) => ({
    status: 200,
    code: 1,
    message: "Thành công",
    data: {
      user: {
        username: "xuyendtk",
        id: "123456",
        name: "Xuyen Xuyen",
        email: "admin@gmail.com",
        phone: "0971965596",
        createdBy: "admin",
        updatedBy: "admin",
        status: "active",
        role: "superadmin",
        rules: [],
        createdAt: "",
        updatedAt: "",
      },
      rules: [],
    },
  }),
  changePassword: (id: string, body: any) => ({
    status: 200,
    code: 1,
    message: "Thành công",
    data: {
      username: body.username,
      id: "123456",
      name: "Xuyen Xuyen",
      email: "admin@gmail.com",
      phone: "0971965596",
      createdBy: "admin",
      updatedBy: "admin",
      status: "active",
      role: "superadmin",
      rules: [],
      createdAt: "",
      updatedAt: "",
    },
  }),
  logout: (id: string) => ({
    status: 200,
    code: 1,
    message: "Thành công",
    data: {
      username: "xuyendtk",
      id: "123456",
      name: "Xuyen Xuyen",
      email: "admin@gmail.com",
      phone: "0971965596",
      createdBy: "admin",
      updatedBy: "admin",
      status: "active",
      role: "superadmin",
      rules: [],
      createdAt: "",
      updatedAt: "",
    },
  }),
  forgotPassword: (body: any) => ({
    status: 200,
    code: 1,
    message: "Thành công",
    data: {
      username: "xuyendtk",
      id: "123456",
      name: "Xuyen Xuyen",
      email: "admin@gmail.com",
      phone: "0971965596",
      createdBy: "admin",
      updatedBy: "admin",
      status: "active",
      role: "superadmin",
      rules: [],
      createdAt: "",
      updatedAt: "",
    },
  }),
  resetPassword: (body: any) => ({
    status: 200,
    code: 1,
    message: "Thành công",
    data: {
      username: "xuyendtk",
      id: "123456",
      name: "Xuyen Xuyen",
      email: "admin@gmail.com",
      phone: "0971965596",
      createdBy: "admin",
      updatedBy: "admin",
      status: "active",
      role: "superadmin",
      rules: [],
      createdAt: "",
      updatedAt: "",
    },
  }),
  uploadAvatar: (body: any) => ({
    status: 200,
    code: 1,
    message: "Thành công",
    data: {
      username: "xuyendtk",
      id: "123456",
      name: "Xuyen Xuyen",
      email: "admin@gmail.com",
      phone: "0971965596",
      createdBy: "admin",
      updatedBy: "admin",
      status: "active",
      role: "superadmin",
      rules: [],
      createdAt: "",
      updatedAt: "",
    },
  }),
  updateDeviceInfo: (id: string) => ({
    status: 200,
    code: 1,
    message: "Thành công",
    data: {
      username: "xuyendtk",
      id: "123456",
      name: "Xuyen Xuyen",
      email: "admin@gmail.com",
      phone: "0971965596",
      createdBy: "admin",
      updatedBy: "admin",
      status: "active",
      role: "superadmin",
      rules: [],
      createdAt: "",
      updatedAt: "",
    },
  }),
};
