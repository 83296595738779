/* eslint-disable @typescript-eslint/no-unused-vars */
import { InternalError } from "@common/kernel/custom-error";
import ErrorCode from "@common/kernel/error-code";
import FoodBookingGroup from "@domain/entities/food-booking-group-entity";
import {
  IEmployeeListInFoodBookingGroup,
  IGetFoodBookingGroupDetail,
  IGetFoodBookingGroupList,
  IQueryFoodBookingGroup
} from "@domain/interfaces/food-booking-group-interface";

export default abstract class FoodBookingGroupRepository {
  async getList (query?: IQueryFoodBookingGroup): Promise<IGetFoodBookingGroupList> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async create (data: FoodBookingGroup): Promise<IGetFoodBookingGroupDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async update (id: string, body: any): Promise<IGetFoodBookingGroupDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async remove (id: string): Promise<IGetFoodBookingGroupDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async addEmployee (code: string, employees: Array<string>): Promise<IEmployeeListInFoodBookingGroup> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async getEmployee (code: string, query: any): Promise<IEmployeeListInFoodBookingGroup> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}
