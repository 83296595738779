import { DEFAULT_LIMIT } from "@common/constant";
import User from "@domain/entities/user-entity";
import { IGetUserDetail, IGetUserList } from "@domain/interfaces/user-interface";
import UserRepository from "@domain/repositories/user-repository";
import ApiService from "@infrastructure/network/api";
import { injectable } from "inversify";

@injectable()
export default class UserApiRepository implements UserRepository {
  private apiService = new ApiService();

  async getList (query: any): Promise<IGetUserList> {
    const result = await this.apiService.get("/user/list", query);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: {
        items: result.data?.items || [],
        total: result.data?.total || 0,
        limit: result.data?.limit || DEFAULT_LIMIT,
      },
    };
  }

  async update (id: string, body: Partial<User>): Promise<IGetUserDetail> {
    const result = await this.apiService.put(`/user/${id}`, body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async export (query: any): Promise<any> {
    this.apiService.save("/user/export", "GET", "users.xlsx", query);

    return;
  }
}
