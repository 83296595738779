/* eslint-disable @typescript-eslint/no-unused-vars */
import { InternalError } from "@common/kernel/custom-error";
import ErrorCode from "@common/kernel/error-code";
import User from "@domain/entities/user-entity";
import { IGetUserDetail, IGetUserList, IQueryUser } from "@domain/interfaces/user-interface";

export default abstract class UserRepository {
  async getList (query?: IQueryUser): Promise<IGetUserList> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async update (id: string, body: Partial<User>): Promise<IGetUserDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async export (query: IQueryUser): Promise<any> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}
