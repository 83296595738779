/* eslint-disable @typescript-eslint/no-unused-vars */
import { IGetCheckInList } from "@domain/interfaces/check-in-interface";
import CheckInRepository from "@domain/repositories/check-in-repository";
import { injectable } from "inversify";

@injectable()
export default class CheckInMockupRepository implements CheckInRepository {
  private item = {
    id: "123456",
    employee_id: "",
    checkin_ip: "",
    checkin_time: "",
    name: "",
    createdBy: "admin",
    updatedBy: "admin",
    createdAt: "",
    updatedAt: "",
  };
  async getList (query: any): Promise<IGetCheckInList> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: { items: [], total: 0, limit: 10 },
    };
  }
  async resetCheckInDevice (employeeId: string): Promise<any> {
    return;
  }
}
