/* eslint-disable @typescript-eslint/no-unused-vars */
import { IGetCallNumberList, IGetCallNumberDetail, IQueryCallNumber } from "@domain/interfaces/call-number-interface";
import CallNumberRepository from "@domain/repositories/call-number-repository";
import CallNumber from "@domain/entities/call-number-entity";
import callNumberMockup from "@infrastructure/mockup/call-number-mockup";
import { injectable } from "inversify";

@injectable()
export default class CallNumberMockupRepository implements CallNumberRepository {
  async getList (query: IQueryCallNumber): Promise<IGetCallNumberList> {
    const result = callNumberMockup.getList(query);

    return {
      isSuccess: result.code === 1,
      message: result.message,
      data: {
        items: result.data.items,
        total: result.data.total,
        limit: result.data.limit,
      },
    };
  }

  async create (body: CallNumber): Promise<IGetCallNumberDetail> {
    const result = callNumberMockup.create(body);

    return {
      isSuccess: result.code === 1,
      message: result.message,
      data: result.data,
    };
  }

  async update (id: string, body: Partial<CallNumber>): Promise<IGetCallNumberDetail> {
    const result = callNumberMockup.update(id, body);

    return {
      isSuccess: result.code === 1,
      message: result.message,
      data: result.data,
    };
  }

  async remove (id: string): Promise<IGetCallNumberDetail> {
    const result = callNumberMockup.remove(id);
    
    return {
      isSuccess: result.code === 1,
      message: result.message,
      data: result.data,
    };
  }
}
