/* eslint-disable @typescript-eslint/no-unused-vars */
import { InternalError } from "@common/kernel/custom-error";
import ErrorCode from "@common/kernel/error-code";
import AppVersion from "@domain/entities/app-version-entity";
import { IGetAppVersionDetail, IGetAppVersionList, IQueryAppVersion } from "@domain/interfaces/app-version-interface";

export default abstract class AppVersionRepository {
  async getList (query?: IQueryAppVersion): Promise<IGetAppVersionList> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
  async create (data: AppVersion): Promise<IGetAppVersionDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
  async update (id: string, body: any): Promise<IGetAppVersionDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async remove (query: any): Promise<IGetAppVersionDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}
