import { IQueryAccount } from "@domain/interfaces/account-interface";

import repository from "@config/repository";
import AccountRepository from "@domain/repositories/account-repository";

export default async function getLoginAccountDetailUseCase (query?: IQueryAccount) {
  const accountRepository = repository.get<AccountRepository>(AccountRepository);

  return await accountRepository.getLoginAccountDetail(query);
}
