export class DomainError extends Error {
  code: number;
  message: string;
  status: number;
  data: object;

  constructor (
    errorCode: { message: string; code: number },
    message?: string,
    data?: object,
    status = HttpStatusCode.SERVER
  ) {
    super(message);
    this.code = errorCode.code || 0;
    this.message = message || errorCode.message;
    this.data = data || {};
    this.status = status;
    Error.caller(this, this.constructor);
    Object.setPrototypeOf(this, DomainError.prototype);
  }
}

export class InternalError extends DomainError {
  constructor (
    errorCode: { message: string; code: number },
    message?: string,
    data?: object,
    status = HttpStatusCode.SERVER
  ) {
    super(errorCode, message, data, status);
  }
}

export class ClientError extends DomainError {
  constructor (
    errorCode: { message: string; code: number },
    message?: string,
    data?: object,
    status = HttpStatusCode.CLIENT
  ) {
    super(errorCode, message, data, status);
  }
}

export const HttpStatusCode = {
  INFO: 100,
  SUCCESS: 200,
  REDIRECT: 300,
  CLIENT: 400,
  SERVER: 500,
};

const CustomError = {
  ClientError,
  InternalError,
};

export default CustomError;
