import ErrorCode, { API_APP_ERROR, API_SYS_ERROR } from "@common/kernel/error-code";
import { createSign } from "@common/utils/sign";
import { getSessionToken, removeSession } from "@infrastructure/session";
import axios, { AxiosError } from "axios";

export interface IApiResponse {
  status: number;
  code: number;
  message: string;
  data: any;
}

export default class CallApiService {
  host: string;

  constructor () {
    this.host = gConfig.callApi.host;
  }

  private getHeader = (data: any) => ({
    product: gConfig.callApi.product,
    token: gConfig.callApi.token,
    authorization: getSessionToken(),
    sign: createSign(data, gConfig.callApi.key + gConfig.callApi.product),
  });

  private sessionTimeout = (code: number) => {
    if (code === ErrorCode.SESSION_TIMEOUT.code) {
      removeSession();
    }
  };

  async get (path: string, query: object = {}) {
    try {
      const url = `${this.host}${path}`;
      const response = await axios.get(url, {
        params: query,
        headers: this.getHeader(query),
      });

      if (!response || !response.data) return API_SYS_ERROR as IApiResponse;

      const result: IApiResponse = response.data;
      this.sessionTimeout(result.code);

      return result;
    } catch (error) {
      console.log(error);

      if (!axios.isAxiosError(error)) return API_APP_ERROR;

      const err: AxiosError = error;

      const result = <IApiResponse>err.response?.data;

      if (result?.code) {
        this.sessionTimeout(result.code);

        return result;
      }

      return API_APP_ERROR;
    }
  }

  async post (path: string, body: object, query?: object) {
    try {
      const url = `${this.host}${path}`;

      const response = await axios.post(url, body, {
        headers: this.getHeader(query),
        params: query,
      });

      if (!response || !response.data) return API_SYS_ERROR;
      const result = response.data as IApiResponse;

      this.sessionTimeout(result.code);

      return result;
    } catch (error) {
      if (!axios.isAxiosError(error)) return API_APP_ERROR;

      const err = error as AxiosError;

      const result = err.response?.data as IApiResponse;

      if (result?.code) {
        this.sessionTimeout(result.code);

        return result;
      }

      return API_APP_ERROR;
    }
  }

  async put (path: string, body: object, query?: object) {
    try {
      const url = `${this.host}${path}`;
      const response = await axios.put(url, body, {
        headers: this.getHeader(body),
        params: query,
      });

      if (!response || !response.data) return API_SYS_ERROR;

      const result = response.data as IApiResponse;

      this.sessionTimeout(result.code);

      return result;
    } catch (error) {
      if (!axios.isAxiosError(error)) return API_APP_ERROR;

      const err = error as AxiosError;

      const result = err.response?.data as IApiResponse;

      if (result?.code) {
        this.sessionTimeout(result.code);

        return result;
      }

      return API_APP_ERROR;
    }
  }

  async remove (path: string, query: object = {}) {
    try {
      const url = `${this.host}${path}`;
      const response = await axios.delete(url, { headers: this.getHeader(query) });

      if (!response || !response.data) return API_SYS_ERROR;

      const result = response.data as IApiResponse;

      this.sessionTimeout(result.code);

      return result;
    } catch (error) {
      if (!axios.isAxiosError(error)) return API_APP_ERROR;

      const err = error as AxiosError;

      const result = err.response?.data as IApiResponse;

      if (result?.code) {
        this.sessionTimeout(result.code);

        return result;
      }

      return API_APP_ERROR;
    }
  }
}
