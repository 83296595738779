/* eslint-disable @typescript-eslint/no-unused-vars */
import TestPhone from "@domain/entities/test-phone-entity";
import { IGetTestPhoneDetail, IGetTestPhoneList, IQueryTestPhone } from "@domain/interfaces/test-phone-interface";
import TestPhoneRepository from "@domain/repositories/test-phone-repository";
import { injectable } from "inversify";

@injectable()
export default class TestPhoneMockupRepository implements TestPhoneRepository {
  private item = {
    id: "",
    phone: "",
    time: "",
    created_by: "admin",
  };
  async getList (query: IQueryTestPhone): Promise<IGetTestPhoneList> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: { items: [], total: 0, limit: 10 },
    };
  }

  async create (body: TestPhone): Promise<IGetTestPhoneDetail> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: this.item,
    };
  }

  async remove (id: string): Promise<IGetTestPhoneDetail> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: this.item,
    };
  }
}
