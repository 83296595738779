import crypto from "./crypto";
import { diffSeconds } from "./date";

export function createSign (data: any, secret: string) {
  const keys = Object.keys(data).sort();
  let stringSign = "";

  keys.forEach((key) => {
    stringSign += data[key] ? `${key}=${data[key] || ""}&` : "";
  });
  stringSign += `secret=${secret}`;
  // console.log(keys);
  // console.log(stringSign);

  return crypto.sha256(stringSign).toUpperCase();
}

export function checkSignTokenTimestamp (timestamp: string) {
  // console.log(diffSeconds(new Date(), new Date(parseInt(timestamp))), 300);
  // console.log(Date.now());

  if (diffSeconds(new Date(), new Date(parseInt(timestamp))) < 300) return true;

  return false;
}
