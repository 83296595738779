import crypto from "@common/utils/crypto";
import repository from "@config/repository";
import AccountRepository from "@domain/repositories/account-repository";

export default async function resetPasswordUseCase (values: { id: string; password: string }) {
  const accountRepository = repository.get<AccountRepository>(AccountRepository);

  return await accountRepository.resetPassword({
    id: values.id,
    password: crypto.sha256(values.password),
  });
}
