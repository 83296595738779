import "@config/index";

import "/public/css/tailwind.css";

import "ant-design-vue/dist/reset.css";

import "/public/css/ant-design.css";
import "/public/css/responsive.css";

import AppLoading from "@interfaces/components/common/AppLoading.vue";
import App from "./App.vue";

import i18n from "@infrastructure/i18n";
import router from "@infrastructure/router";
import Antd from "ant-design-vue";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

import { DesktopOutlined, DownOutlined, FileOutlined, KeyOutlined, PieChartOutlined, TeamOutlined, UserOutlined } from "@ant-design/icons-vue";
import { createPinia } from "pinia";
import { PiniaLogger } from "pinia-logger";
import { createApp } from "vue";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate).use(
  PiniaLogger({
    expanded: true,
    disabled: gConfig.env === "production",
    // use a filter to only log certain actions
    // filter: ({ name }) => name !== "incrementCounter",
    // alternatively, you can specify the actions you want to log
    // if undefined, all actions will be logged
    // actions: ["clear"],
  })
);
createApp(App)
  .use(pinia)
  .use(router)
  .use(i18n)
  .use(Antd)
  .component(PieChartOutlined.name, PieChartOutlined)
  .component(TeamOutlined.name, TeamOutlined)
  .component(DesktopOutlined.name, DesktopOutlined)
  .component(FileOutlined.name, FileOutlined)
  .component(UserOutlined.name, UserOutlined)
  .component(KeyOutlined.name, KeyOutlined)
  .component(DownOutlined.name, DownOutlined)
  .component("AppLoading", AppLoading)
  .mount("#app");
