/* eslint-disable @typescript-eslint/no-unused-vars */
import { IGetCallQueueList, IGetCallQueueDetail, IQueryCallQueue } from "@domain/interfaces/call-queue-interface";
import CallQueueRepository from "@domain/repositories/call-queue-repository";
import CallQueue from "@domain/entities/call-queue-entity";
import callQueueMockup from "@infrastructure/mockup/call-queue-mockup";
import { injectable } from "inversify";

@injectable()
export default class CallQueueMockupRepository implements CallQueueRepository {
  async getList (query: IQueryCallQueue): Promise<IGetCallQueueList> {
    const result = callQueueMockup.getList(query);

    return {
      isSuccess: result.code === 1,
      message: result.message,
      data: {
        items: result.data.items,
        total: result.data.total,
        limit: result.data.limit,
      },
    };
  }

  async create (body: CallQueue): Promise<IGetCallQueueDetail> {
    const result = callQueueMockup.create(body);

    return {
      isSuccess: result.code === 1,
      message: result.message,
      data: result.data,
    };
  }

  async update (id: string, body: Partial<CallQueue>): Promise<IGetCallQueueDetail> {
    const result = callQueueMockup.update(id, body);

    return {
      isSuccess: result.code === 1,
      message: result.message,
      data: result.data,
    };
  }

  async remove (id: string): Promise<IGetCallQueueDetail> {
    const result = callQueueMockup.remove(id);
    
    return {
      isSuccess: result.code === 1,
      message: result.message,
      data: result.data,
    };
  }
}
