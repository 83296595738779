import { DEFAULT_LIMIT } from "@common/constant";
import HomeMenu from "@domain/entities/home-menu-entity";
import { IGetHomeMenuDetail, IGetHomeMenuList, IQueryHomeMenu } from "@domain/interfaces/home-menu-interface";
import HomeMenuRepository from "@domain/repositories/home-menu-repository";
import ApiService from "@infrastructure/network/api";
import { injectable } from "inversify";

@injectable()
export default class HomeMenuApiRepository implements HomeMenuRepository {
  private apiService = new ApiService();

  async getList (query: IQueryHomeMenu): Promise<IGetHomeMenuList> {
    const result = await this.apiService.get("/home-menu/list", query);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: {
        items: result.data?.items || [],
        total: result.data?.total || 0,
        limit: result.data?.limit || DEFAULT_LIMIT,
      },
    };
  }

  async create (body: HomeMenu): Promise<IGetHomeMenuDetail> {
    const result = await this.apiService.post("/home-menu", body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async update (id: string, body: Partial<HomeMenu>): Promise<IGetHomeMenuDetail> {
    const result = await this.apiService.put(`/home-menu/${id}`, body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async remove (id: string): Promise<IGetHomeMenuDetail> {
    const result = await this.apiService.remove(`/home-menu/${id}`);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }
}
