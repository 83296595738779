/* eslint-disable @typescript-eslint/no-unused-vars */
import { IGetAccountList } from "@domain/interfaces/account-interface";
import AccountRepository from "@domain/repositories/account-repository";
import accountMockup from "@infrastructure/mockup/account-mockup";
import { injectable } from "inversify";

@injectable()
export default class AccountMockupRepository implements AccountRepository {
  async getList (query: any): Promise<IGetAccountList> {
    const result = accountMockup.getList(query);

    return {
      isSuccess: result.code === 1,
      message: result.message,
      data: {
        items: result.data.items,
        total: result.data.total,
        limit: result.data.limit,
      },
    };
  }

  async create (body: any) {
    const result = accountMockup.create(body);

    return {
      isSuccess: result.code === 1,
      message: result.message,
      data: result.data,
    };
  }

  async update (id: string, body: any) {
    const result = accountMockup.update(id, body);

    return {
      isSuccess: result.code === 1,
      message: result.message,
      data: result.data,
    };
  }

  async remove (id: string) {
    const result = accountMockup.remove(id);

    return {
      isSuccess: result.code === 1,
      message: result.message,
      data: result.data,
    };
  }

  async login (body: any) {
    const result = accountMockup.login(body);

    return {
      isSuccess: result.code === 1,
      message: result.message,
      data: {
        token: result.data.token,
        user: result.data.user,
      },
    };
  }

  async getLoginAccountDetail (query: any) {
    const result = accountMockup.getLoginAccountDetail(query);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async logout (id: string) {
    const result = accountMockup.logout(id);

    return {
      isSuccess: result.code === 1,
      message: result.message,
      data: result.data,
    };
  }

  async changePassword (id: string, body: any) {
    const result = accountMockup.changePassword(id, body);

    return {
      isSuccess: result.code === 1,
      message: result.message,
      data: result.data,
    };
  }

  async forgotPassword (body: any) {
    const result = accountMockup.forgotPassword(body);

    return {
      isSuccess: result.code === 1,
      message: result.message,
      data: result.data,
    };
  }

  async resetPassword (body: any) {
    const result = accountMockup.resetPassword(body);

    return {
      isSuccess: result.code === 1,
      message: result.message,
      data: result.data,
    };
  }

  async uploadAvatar (body: any) {
    const result = accountMockup.uploadAvatar(body);

    return {
      isSuccess: result.code === 1,
      message: result.message,
      data: result.data,
    };
  }

  async updateDeviceInfo (id: string) {
    const result = accountMockup.updateDeviceInfo(id);

    return {
      isSuccess: result.code === 1,
      message: result.message,
      data: result.data,
    };
  }
}
