import { DEFAULT_LIMIT } from "@common/constant";
import { IGetAccountList } from "@domain/interfaces/account-interface";
import AccountRepository from "@domain/repositories/account-repository";
import ApiService from "@infrastructure/network/api";
import { injectable } from "inversify";

@injectable()
export default class AccountApiRepository implements AccountRepository {
  private apiService = new ApiService();

  async getList (query: any): Promise<IGetAccountList> {
    const result = await this.apiService.get("/account", query);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: {
        items: result.data?.items || [],
        total: result.data?.total || 0,
        limit: result.data?.limit || DEFAULT_LIMIT,
      },
    };
  }

  async create (body: any) {
    const result = await this.apiService.post("/account", body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async update (id: string, body: any) {
    const result = await this.apiService.put(`/account/${id}`, body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async remove (id: string) {
    const result = await this.apiService.remove(`/account/${id}`);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async updateDeviceInfo (id: string) {
    const result = await this.apiService.put(`/account/${id}/device-info`, {});

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async login (body: any) {
    const result = await this.apiService.post("/account/login", body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }
  async getLoginAccountDetail (query: any) {
    const result = await this.apiService.get("/account/login/detail", query);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async logout (id: string) {
    const result = await this.apiService.put(`/account/${id}/logout`, {});

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async changePassword (id: string, body: any) {
    const result = await this.apiService.put(`/account/${id}/change-password`, body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async forgotPassword (body: any) {
    const result = await this.apiService.post("/account/forgot-password", body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async resetPassword (body: any) {
    const result = await this.apiService.post("/account/reset-password", body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async uploadAvatar (body: any) {
    const result = await this.apiService.upload("/account/upload/avatar", body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }
}
