/* eslint-disable @typescript-eslint/no-unused-vars */
import { InternalError } from "@common/kernel/custom-error";
import ErrorCode from "@common/kernel/error-code";
import CallAgent from "@domain/entities/call-agent-entity";
import { IGetCallAgentAccessToken, IGetCallAgentDetail, IGetCallAgentList, IQueryCallAgent } from "@domain/interfaces/call-agent-interface";

export default abstract class CallAgentRepository {
  async getList (query?: IQueryCallAgent): Promise<IGetCallAgentList> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async create (data: CallAgent): Promise<IGetCallAgentDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async update (id: string, body: Partial<CallAgent>): Promise<IGetCallAgentDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async remove (id: string): Promise<IGetCallAgentDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async getAccessToken (userId: string): Promise<IGetCallAgentAccessToken> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}
