import repository from "@config/repository";
import AccountRepository from "@domain/repositories/account-repository";

export default async function loginUseCase (values: { username: string; password: string }) {
  const accountRepository = repository.get<AccountRepository>(AccountRepository);

  return await accountRepository.login({
    username: values.username,
    password: values.password,
  });
}
