import { DEFAULT_LIMIT } from "@common/constant";
import CallNumber from "@domain/entities/call-number-entity";
import { IGetCallNumberDetail, IGetCallNumberList, IQueryCallNumber } from "@domain/interfaces/call-number-interface";
import CallNumberRepository from "@domain/repositories/call-number-repository";
import CallApiService from "@infrastructure/network/call-api";
import { injectable } from "inversify";

@injectable()
export default class CallNumberApiRepository implements CallNumberRepository {
  private api = new CallApiService();

  async getList (query: IQueryCallNumber): Promise<IGetCallNumberList> {
    const result = await this.api.get("/number", query);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: {
        items: result.data?.items || [],
        total: result.data?.total || 0,
        limit: result.data?.limit || DEFAULT_LIMIT,
      },
    };
  }

  async create (body: CallNumber): Promise<IGetCallNumberDetail> {
    const result = await this.api.post("/number", body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async update (id: string, body: Partial<CallNumber>): Promise<IGetCallNumberDetail> {
    const result = await this.api.put(`/number/${id}`, body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async remove (id: string): Promise<IGetCallNumberDetail> {
    const result = await this.api.remove(`/number/${id}`);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }
}
