/* eslint-disable @typescript-eslint/no-unused-vars */
import { InternalError } from "@common/kernel/custom-error";
import ErrorCode from "@common/kernel/error-code";
import Rule from "@domain/entities/rule-entity";
import { IGetRuleDetail, IGetRuleList, IGetRuleSubjectList, IQueryRule } from "@domain/interfaces/rule-interface";

export default abstract class RuleRepository {
  async getList (query?: IQueryRule): Promise<IGetRuleList> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async create (data: Rule): Promise<IGetRuleDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async update (id: string, body: any): Promise<IGetRuleDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async remove (query: any): Promise<IGetRuleDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async getSubject (): Promise<IGetRuleSubjectList> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}
