/* eslint-disable @typescript-eslint/no-unused-vars */
import Segment from "@domain/entities/segment-entity";
import { IGetSegmentDetail, IGetSegmentList, IGetSegmentUserList, IQuerySegment } from "@domain/interfaces/segment-interface";
import SegmentRepository from "@domain/repositories/segment-repository";
import { injectable } from "inversify";

@injectable()
export default class SegmentMockupRepository implements SegmentRepository {
  private item = {
    id: "123456",
    name: "",
    filter: { or: [{ and: [{ fact: "gender", operator: "equal", value: "male" }] }] },
    user_deleted: false,
    created_by: "admin",
    updated_by: "admin",
    created_at: "",
    updated_at: "",
  };
  async getUserList (query: IQuerySegment): Promise<IGetSegmentUserList> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: { items: [], total: 0, limit: 10 },
    };
  }

  async getList (query: IQuerySegment): Promise<IGetSegmentList> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: { items: [], total: 0, limit: 10 },
    };
  }

  async create (body: Segment): Promise<IGetSegmentDetail> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: this.item,
    };
  }

  async update (id: string, body: Partial<Segment>): Promise<IGetSegmentDetail> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: this.item,
    };
  }

  async remove (id: string): Promise<IGetSegmentDetail> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: this.item,
    };
  }
}
