/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  IEmployeeListInFoodBookingGroup,
  IGetFoodBookingGroupDetail,
  IGetFoodBookingGroupList,
  IQueryFoodBookingGroup
} from "@domain/interfaces/food-booking-group-interface";
import FoodBookingGroupRepository from "@domain/repositories/food-booking-group-repository";
import { injectable } from "inversify";

@injectable()
export default class FoodBookingGroupMockupRepository implements FoodBookingGroupRepository {
  addEmployee (code: string, employees: string[]): Promise<IEmployeeListInFoodBookingGroup> {
    throw new Error("Method not implemented.");
  }

  getEmployee (code: string, query: any): Promise<IEmployeeListInFoodBookingGroup> {
    throw new Error("Method not implemented.");
  }

  private item = {
    id: "123456",
    name: "",
    code: "",
    created_by: "admin",
    updated_by: "admin",
    created_at: "",
    updated_at: "",
  };
  async getList (query: IQueryFoodBookingGroup): Promise<IGetFoodBookingGroupList> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: { items: [], total: 0, limit: 10 },
    };
  }

  async create (body: any): Promise<IGetFoodBookingGroupDetail> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: this.item,
    };
  }

  async update (id: string, body: any): Promise<IGetFoodBookingGroupDetail> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: this.item,
    };
  }

  async remove (id: string): Promise<IGetFoodBookingGroupDetail> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: this.item,
    };
  }
}
