import { DEFAULT_LIMIT } from "@common/constant";
import TestPhone from "@domain/entities/test-phone-entity";
import { IGetTestPhoneDetail, IGetTestPhoneList, IQueryTestPhone } from "@domain/interfaces/test-phone-interface";
import TestPhoneRepository from "@domain/repositories/test-phone-repository";
import ApiService from "@infrastructure/network/api";
import { injectable } from "inversify";

@injectable()
export default class TestPhoneApiRepository implements TestPhoneRepository {
  private apiService = new ApiService();

  async getList (query: IQueryTestPhone): Promise<IGetTestPhoneList> {
    const result = await this.apiService.get("/configurations/test-phone", query);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: {
        items: result.data?.items || [],
        total: result.data?.total || 0,
        limit: result.data?.limit || DEFAULT_LIMIT,
      },
    };
  }

  async create (body: TestPhone): Promise<IGetTestPhoneDetail> {
    const result = await this.apiService.post("/configurations/test-phone", body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async remove (phone: string): Promise<IGetTestPhoneDetail> {
    const result = await this.apiService.remove(`/configurations/test-phone/${phone}`);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }
}
