import { DEFAULT_LIMIT } from "@common/constant";
import CallQueue from "@domain/entities/call-queue-entity";
import { IGetCallQueueDetail, IGetCallQueueList, IQueryCallQueue } from "@domain/interfaces/call-queue-interface";
import CallQueueRepository from "@domain/repositories/call-queue-repository";
import CallApiService from "@infrastructure/network/call-api";
import { injectable } from "inversify";

@injectable()
export default class CallQueueApiRepository implements CallQueueRepository {
  private api = new CallApiService();

  async getList (query: IQueryCallQueue): Promise<IGetCallQueueList> {
    const result = await this.api.get("/queue", query);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: {
        items: result.data?.items || [],
        total: result.data?.total || 0,
        limit: result.data?.limit || DEFAULT_LIMIT,
      },
    };
  }

  async create (body: CallQueue): Promise<IGetCallQueueDetail> {
    const result = await this.api.post("/queue", body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async update (id: string, body: Partial<CallQueue>): Promise<IGetCallQueueDetail> {
    const result = await this.api.put(`/queue/${id}`, body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async remove (id: string): Promise<IGetCallQueueDetail> {
    const result = await this.api.remove(`/queue/${id}`);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }
}
