/* eslint-disable @typescript-eslint/no-unused-vars */
import { InternalError } from "@common/kernel/custom-error";
import ErrorCode from "@common/kernel/error-code";
import CallNumber from "@domain/entities/call-number-entity";
import { IGetCallNumberDetail, IGetCallNumberList, IQueryCallNumber } from "@domain/interfaces/call-number-interface";

export default abstract class CallNumberRepository {
  async getList (query?: IQueryCallNumber): Promise<IGetCallNumberList> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async create (data: CallNumber): Promise<IGetCallNumberDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async update (id: string, body: Partial<CallNumber>): Promise<IGetCallNumberDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async remove (id: string): Promise<IGetCallNumberDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}
