import { DEFAULT_LIMIT } from "@common/constant";
import {
  IEmployeeListInFoodBookingGroup,
  IGetFoodBookingGroupDetail,
  IGetFoodBookingGroupList,
  IQueryFoodBookingGroup
} from "@domain/interfaces/food-booking-group-interface";
import FoodBookingGroupRepository from "@domain/repositories/food-booking-group-repository";
import ApiService from "@infrastructure/network/api";
import { injectable } from "inversify";

@injectable()
export default class FoodBookingGroupApiRepository implements FoodBookingGroupRepository {
  private apiService = new ApiService();

  async getList (query: IQueryFoodBookingGroup): Promise<IGetFoodBookingGroupList> {
    const result = await this.apiService.get("/food-booking-group", query);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: {
        items: result.data?.items || [],
        total: result.data?.total || 0,
        limit: result.data?.limit || DEFAULT_LIMIT,
      },
    };
  }

  async create (body: any): Promise<IGetFoodBookingGroupDetail> {
    const result = await this.apiService.post("/food-booking-group", body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async update (code: string, body: any): Promise<IGetFoodBookingGroupDetail> {
    const result = await this.apiService.put(`/food-booking-group/${code}`, body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async remove (code: string): Promise<IGetFoodBookingGroupDetail> {
    const result = await this.apiService.remove(`/food-booking-group/${code}`);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async addEmployee (code: string, employees: Array<string>): Promise<IEmployeeListInFoodBookingGroup> {
    const result = await this.apiService.put(`/food-booking-group/${code}/employee`, { employees });

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: {
        items: result.data?.items || [],
        total: result.data?.total || 0,
        limit: result.data?.limit || DEFAULT_LIMIT,
      },
    };
  }
  async getEmployee (code: string, query: any): Promise<IEmployeeListInFoodBookingGroup> {
    const result = await this.apiService.get(`/food-booking-group/${code}/employee`, query);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: {
        items: result.data?.items || [],
        total: result.data?.total || 0,
        limit: result.data?.limit || DEFAULT_LIMIT,
      },
    };
  }
}
