/* eslint-disable @typescript-eslint/no-unused-vars */
import { InternalError } from "@common/kernel/custom-error";
import ErrorCode from "@common/kernel/error-code";
import Segment from "@domain/entities/segment-entity";
import { IGetSegmentDetail, IGetSegmentList, IGetSegmentUserList, IQuerySegment } from "@domain/interfaces/segment-interface";

export default abstract class SegmentRepository {
  async getUserList (query?: IQuerySegment): Promise<IGetSegmentUserList> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
  async getList (query?: IQuerySegment): Promise<IGetSegmentList> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async create (data: Segment): Promise<IGetSegmentDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async update (id: string, body: Partial<Segment>): Promise<IGetSegmentDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async remove (id: string): Promise<IGetSegmentDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}
