/* eslint-disable @typescript-eslint/no-unused-vars */
import { IGetUserDetail, IGetUserList, IQueryUser } from "@domain/interfaces/user-interface";
import UserRepository from "@domain/repositories/user-repository";
import { injectable } from "inversify";

@injectable()
export default class UserMockupRepository implements UserRepository {
  private item = {
    id: "123456",
    name: "",
    employee_id: "",
    phone: "",
    organization_id: "",
    created_by: "admin",
    updated_by: "admin",
    created_at: "",
    updated_at: "",
    avatar: "",
    branch_id: "",
    status: "",
    login_device: {
      app_version: "",
      device_id: "",
      firebase_token: "",
      os_version: "",
      platform: "",
      login_at: "",
      is_login: false,
    },
    kycFace: {
      id: "",
      files: [],
      createdAt: "",
      updatedAt: "",
    },
    dob: "",
  };
  async getList (query: any): Promise<IGetUserList> {
    throw new Error("Method not implemented.");
  }

  async searchEmployee (query: IQueryUser): Promise<IGetUserList> {
    throw new Error("Method not implemented.");
  }

  async update (id: string, body: any): Promise<IGetUserDetail> {
    throw new Error("Method not implemented.");
  }

  async export (query: any): Promise<any> {
    throw new Error("Method not implemented.");
  }
}
