/* eslint-disable @typescript-eslint/no-unused-vars */
import { IUploadImageToStatic } from "@domain/interfaces/upload-interface";
import UploadRepository from "@domain/repositories/upload-repository";
import { injectable } from "inversify";

@injectable()
export default class UploadRepositoryMockupRepository implements UploadRepository {
  async uploadImageToStatic (files: Array<File>): Promise<IUploadImageToStatic> {
    return {
      error_code: 1,
      message: "OK",
      status: 1,
      data: [
        {
          url: "",
          id: "",
        },
      ],
    };
  }
}
