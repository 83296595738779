/* eslint-disable @typescript-eslint/no-unused-vars */
import HomeMenu from "@domain/entities/home-menu-entity";
import { IGetHomeMenuDetail, IGetHomeMenuList, IQueryHomeMenu } from "@domain/interfaces/home-menu-interface";
import HomeMenuRepository from "@domain/repositories/home-menu-repository";
import { injectable } from "inversify";

@injectable()
export default class HomeMenuMockupRepository implements HomeMenuRepository {
  private item = {
    id: "123456",
    code: "",
    name: "",
    icon_url: "",
    active_version: "",
    active: true,
    created_by: "admin",
    updated_by: "admin",
    created_at: "",
    updated_at: "",
  };

  async getList (query: IQueryHomeMenu): Promise<IGetHomeMenuList> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: { items: [], total: 0, limit: 10 },
    };
  }

  async create (HomeMenu: HomeMenu): Promise<IGetHomeMenuDetail> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: this.item,
    };
  }

  async update (id: string, body: Partial<HomeMenu>): Promise<IGetHomeMenuDetail> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: this.item,
    };
  }

  async remove (id: string): Promise<IGetHomeMenuDetail> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: this.item,
    };
  }
}
