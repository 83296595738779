import {
  IAddConversationMember,
  IAddConversationMemberBody,
  IGetConversationList,
  IGetConversationMember,
  IGetConversationMemberQuery,
  IGetConversationQuery,
  IRemoveConversationMember,
  IRemoveConversationMemberBody
} from "@domain/interfaces/conversation-interface";
import { injectable } from "inversify";

import ChatRepository from "@domain/repositories/chat-repository";
import ApiService from "@infrastructure/network/api";

@injectable()
export default class ChatApiRepository implements ChatRepository {
  private apiService = new ApiService();

  async getConversationList (query: IGetConversationQuery): Promise<IGetConversationList> {
    const result = await this.apiService.get("/chat/conversation", query);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async getConversationMember (id: string, query: IGetConversationMemberQuery): Promise<IGetConversationMember> {
    const result = await this.apiService.get(`/chat/conversation/members/${id}`, query);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async removeConversationMember (body: IRemoveConversationMemberBody): Promise<IRemoveConversationMember> {
    const result = await this.apiService.put("/chat/conversation/members", body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async addConversationMember (body: IAddConversationMemberBody): Promise<IAddConversationMember> {
    const result = await this.apiService.post("/chat/conversation/members", body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }
}
