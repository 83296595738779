/* eslint-disable @typescript-eslint/no-unused-vars */
import { InternalError } from "@common/kernel/custom-error";
import ErrorCode from "@common/kernel/error-code";
import HomeMenu from "@domain/entities/home-menu-entity";
import { IGetHomeMenuDetail, IGetHomeMenuList, IQueryHomeMenu } from "@domain/interfaces/home-menu-interface";

export default abstract class HomeMenuRepository {
  async getList (query?: IQueryHomeMenu): Promise<IGetHomeMenuList> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async create (data: HomeMenu): Promise<IGetHomeMenuDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async update (id: string, body: Partial<HomeMenu>): Promise<IGetHomeMenuDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async remove (id: string): Promise<IGetHomeMenuDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}
