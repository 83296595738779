import changePasswordUseCase from "@domain/use-cases/account/change-password";
import createAccountUseCase from "@domain/use-cases/account/create-account";
import forgotPasswordUseCase from "@domain/use-cases/account/forgot-password";
import getAccountListUseCase from "@domain/use-cases/account/get-account-list";
import getLoginAccountDetailUseCase from "@domain/use-cases/account/get-login-account-detail";
import loginUseCase from "@domain/use-cases/account/login";
import logoutUseCase from "@domain/use-cases/account/logout";
import removeAccountUseCase from "@domain/use-cases/account/remove-account";
import resetPasswordUseCase from "@domain/use-cases/account/reset-password";
import updateAccountUseCase from "@domain/use-cases/account/update-account";
import updateDeviceInfoUseCase from "@domain/use-cases/account/update-device-info";

import Account from "@domain/entities/account-entity";

import { defineAbility } from "@casl/ability";
import { routesName } from "@common/route";
import { ACCOUNT_ROLE } from "@domain/constant/role-constant";
import { ACCOUNT_RULE, RULE_ACTION, RULE_SUBJECT } from "@domain/constant/rule-constant";
import { AccountFormState, IChangePasswordBody, ILoginBody, IQueryAccount } from "@domain/interfaces/account-interface";
import { defineStore } from "pinia";
import { useAdminMenuStore } from "./admin-menu-store";
import { useAlertStore } from "./alert-store";

export const useAccountStore = defineStore("account", {
  state: () => ({
    loading: false,
    loginUser: new Account(),
    token: "",
    table: {
      fetching: false,
      data: [] as Array<Account>,
      total: 0,
      limit: 10,
      page: 1,
      searchQuery: <IQueryAccount>{},
    },
    adminAccessRoutes: [routesName.admin.home] as string[],
    adminLayoutLoading: true,
    ability: defineAbility((can) => {
      can("home", "read");
    }),
  }),

  getters: {
    getLoginUser: (state) => state.loginUser,
    isLogin: (state) => !!state.loginUser?.id,
    searchQuery: (state) => state.table.searchQuery,
  },
  persist: {
    key: "dataAccess",
    paths: ["loginUser", "token", "adminAccessRoutes"],
  },
  actions: {
    updateDeviceInfo () {
      updateDeviceInfoUseCase(this.loginUser.id);
    },
    async login (data: ILoginBody) {
      const result = await loginUseCase(data);
      const alert = useAlertStore();

      if (result.isSuccess) {
        this.loginUser = result.data.user;
        this.token = result.data.token;

        alert.success(`Welcome back ${this.loginUser.name}!`);
      } else {
        alert.error(result.message);
      }

      return result.isSuccess;
    },
    async logout () {
      const result = await logoutUseCase(this.loginUser.id);

      this.loginUser = new Account();
      this.token = "";

      if (result.isSuccess) useAlertStore().success("Đăng xuất thành công");

      return result.isSuccess;
    },
    async changePassword (data: IChangePasswordBody) {
      this.loading = true;

      const result = await changePasswordUseCase(this.loginUser.id, data);
      this.loading = false;

      useAlertStore().success(result.message);
    },
    async resetPassword () {
      this.loading = true;
      const result = await resetPasswordUseCase({ id: this.loginUser.id, password: "" });

      useAlertStore().success(result.message);
      this.loading = false;
    },
    async forgotPassword () {
      this.loading = true;

      await forgotPasswordUseCase(this.loginUser.email);
      this.loading = false;
    },
    // ========= table =============
    async getAccountList (query?: IQueryAccount) {
      this.table.fetching = true;

      const result = await getAccountListUseCase(query);
      this.table.page = query?.page || 1;
      this.table.data = result.data.items?.map((item, index) => ({ ...item, key: index }));
      this.table.total = result.data.total || result.data.items.length;
      this.table.fetching = false;
    },

    async createAccount (data: AccountFormState) {
      const result = await createAccountUseCase(new Account(data));
      const alert = useAlertStore();

      if (!result.isSuccess) return alert.error(result.message);

      alert.success(result.message);
      this.table.data.unshift(new Account(result.data));

      return result.isSuccess;
    },
    async updateAccount (data: AccountFormState) {
      const result = await updateAccountUseCase(data.id, new Account(data));
      const alert = useAlertStore();

      if (!result.isSuccess) return alert.error(result.message);

      alert.success(result.message);
      const index = this.table.data.findIndex((item) => item.id === data.id);

      this.table.data.splice(index, 1, new Account(result.data));

      return result.isSuccess;
    },

    async removeAccount (id: string) {
      const result = await removeAccountUseCase(id);
      const alert = useAlertStore();

      if (!result.isSuccess) return alert.error(result.message);

      alert.success(result.message);
      this.table.data = this.table.data.filter((item) => item.id !== id);

      return result.isSuccess;
    },

    async getLoginAccountDetail () {
      const result = await getLoginAccountDetailUseCase();
      let accessRoutes: string[] = [];

      if (!result.isSuccess) {
        this.adminAccessRoutes = accessRoutes;
        this.adminLayoutLoading = false;
        useAdminMenuStore().setAccountMenu();

        return;
      }

      useAdminMenuStore().setAccountMenu(result.data.user, result.data.rules);
      const routeList = Object.values(routesName.admin);

      this.ability = defineAbility((can) => {
        result.data.rules?.forEach((rule) => {
          can(rule.actions, rule.subject, undefined, JSON.parse(rule.conditions || "{}"));
        });
      });

      if (this.loginUser.role === ACCOUNT_ROLE.superadmin || this.loginUser.rules.includes(ACCOUNT_RULE.readAll)) {
        accessRoutes = routeList;
      } else {
        result.data.rules?.forEach((rule) => {
          if (rule.actions === RULE_ACTION.read && routeList.find((routeName) => routeName === rule.subject)) {
            accessRoutes.push(rule.subject);
          }
        });
      }

      this.adminAccessRoutes = accessRoutes;
      this.adminLayoutLoading = false;
    },
    can (action: string, subject: string, feature?: any) {
      let isAllow = this.ability.can(action, RULE_SUBJECT.all);

      if (!isAllow && feature) {
        const conditions = this.ability.relevantRuleFor(action, subject)?.conditions || {};

        Object.keys(conditions).forEach((key) => {
          if (key === feature) {
            isAllow = true;
          }
        });
      }

      return isAllow;
    },
  },
});
