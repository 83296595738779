/* eslint-disable @typescript-eslint/no-unused-vars */
import { IGetAppVersionDetail, IGetAppVersionList } from "@domain/interfaces/app-version-interface";
import AppVersionRepository from "@domain/repositories/app-version-repository";
import { injectable } from "inversify";

@injectable()
export default class AppVersionMockupRepository implements AppVersionRepository {
  private item = {
    id: "123456",
    text: "",
    value: 100,
    created_by: "admin",
    created_at: "",
  };

  async getList (query: any): Promise<IGetAppVersionList> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: { items: [], total: 0, limit: 10 },
    };
  }

  async create (body: any): Promise<IGetAppVersionDetail> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: this.item,
    };
  }

  async remove (id: string): Promise<IGetAppVersionDetail> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: this.item,
    };
  }

  async update (id: string, body: any): Promise<IGetAppVersionDetail> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: this.item,
    };
  }
}
