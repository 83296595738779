/* eslint-disable @typescript-eslint/no-unused-vars */
export default {
  getList: (query?: any) => ({
    status: 200,
    code: 1,
    message: "Thành công",
    data: {
      items: [
        {
          id: "123456",
          userId: "",
          branchId: "",
          agentId: "",
          type: "",
          avatar: "",
          name: "",
          numberInQueue: -1,
          createdBy: "admin",
          updatedBy: "admin",
          createdAt: "",
          updatedAt: "",
        },
      ],
      total: 1,
      limit: 10,
    },
  }),
  create: (body: any) => ({
    status: 200,
    code: 1,
    message: "Thành công",
    data: {
      id: "123456",
      userId: "",
      branchId: "",
      agentId: "",
      type: "",
      name: "",
      avatar: "",
      numberInQueue: -1,
      createdBy: "admin",
      updatedBy: "admin",
      createdAt: "",
      updatedAt: "",
    },
  }),
  update: (id: string, body: any) => ({
    status: 200,
    code: 1,
    message: "Thành công",
    data: {
      id: "123456",
      userId: "",
      branchId: "",
      agentId: "",
      name: "",
      type: "",
      avatar: "",
      numberInQueue: -1,
      createdBy: "admin",
      updatedBy: "admin",
      createdAt: "",
      updatedAt: "",
    },
  }),
  remove: (id: string) => ({
    status: 200,
    code: 1,
    message: "Thành công",
    data: {
      id: "123456",
      userId: "",
      branchId: "",
      agentId: "",
      type: "",
      avatar: "",
      name: "",
      numberInQueue: -1,
      createdBy: "admin",
      updatedBy: "admin",
      createdAt: "",
      updatedAt: "",
    },
  }),
  getAccessToken: (userId: string) => ({
    status: 200,
    code: 1,
    message: "Thành công",
    data: {
      token: "123456",
      callNumbers: [
        {
          number: "1029",
          callTimeOut: 15,
        },
      ],
      totalTimeOut: 60,
    },
  }),
};
