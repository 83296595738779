export default {
  env: "production",
  host: "https://api.theadvance.com",
  api: {
    host: "https://api.theadvance.com/admin",
    product: "admin",
    token: "ec26e19c0e089369af63a5c2046af12669a3de334dc7c65a4494ccc6d4e9c25b",
  },
  callApi: {
    host: "https://call.theadvance.com/admin",
    demo: "https://call.theadvance.com/call/demo/voice-call",
    product: "admin",
    token: "2b5fa55f4518e8f3cf810fce6591d347a69027d7916cdd54b6665f06b1a3ffe9",
    key: "d8a1266b552663d50d3748b698bd480aa72f53b65e2226fcefb0639e2ae7b32b",
  },
  upload: {
    host: "https://upload.ngocdunggroup.com.vn/upload/images?product=THEADVANCE_APP&token=de4fefbc0f4602388af2",
    maxSizeUpload: 5242880,
  },
};
