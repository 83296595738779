import { IUserRemovePost } from "./../../interfaces/post-interface";
/* eslint-disable @typescript-eslint/no-unused-vars */
import { InternalError } from "@common/kernel/custom-error";
import { IGetPostList, IGetPostQuery, IRemovePost, IRemoveUserPostBody } from "@domain/interfaces/post-interface";

import ErrorCode from "@common/kernel/error-code";

export default abstract class PostRepository {
  async getList (query: IGetPostQuery): Promise<IGetPostList> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async remove (id: string): Promise<IRemovePost> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async removeUserPost (body: IRemoveUserPostBody): Promise<IUserRemovePost> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}
