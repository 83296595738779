export const RULE_ACTION = {
  read: "read",
  create: "create",
  update: "update",
  delete: "delete",
  export: "export",
};

export const ACCOUNT_RULE = {
  readAll: "read|all|everything",
};

export const RULE_SUBJECT = {
  customerRecord: "customerRecord",
  bookingMeal: "bookingMeal",
  all: "all",
};
