/* eslint-disable @typescript-eslint/no-unused-vars */
import { InternalError } from "@common/kernel/custom-error";
import ErrorCode from "@common/kernel/error-code";
import { IGetCustomerRecordDetail, IGetCustomerRecordList, IQueryCustomerRecord } from "@domain/interfaces/customer-record-interface";

export default abstract class CustomerRecordRepository {
  async getList (query?: IQueryCustomerRecord): Promise<IGetCustomerRecordList> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async sendRecordToGroup (id: string): Promise<IGetCustomerRecordDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}
