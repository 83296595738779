import { IGetPostList, IGetPostQuery, IRemovePost, IRemoveUserPostBody, IUserRemovePost } from "@domain/interfaces/post-interface";
import { injectable } from "inversify";

import PostRepository from "@domain/repositories/social/post-repository";
import ApiService from "@infrastructure/network/api";

@injectable()
export default class SocialPostApiRepository implements PostRepository {
  private apiService = new ApiService();

  async getList (query: IGetPostQuery): Promise<IGetPostList> {
    const result = await this.apiService.get("/social/post", query);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async remove (id: string): Promise<IRemovePost> {
    const result = await this.apiService.remove(`/social/post/${id}`);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async removeUserPost (body: IRemoveUserPostBody): Promise<IUserRemovePost> {
    const result = await this.apiService.remove("/social/post/user", body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }
}
