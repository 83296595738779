import Account from "@domain/entities/account-entity";
import { useAccountStore } from "@presentation/store/account-store";

export const getSessionToken = () => {
  const accountStore = useAccountStore();

  return accountStore.token;
};

export const removeSession = () => {
  const accountStore = useAccountStore();
  accountStore.loginUser = new Account();
  accountStore.token = "";
  accountStore.adminAccessRoutes = [];
  window.location.replace("/login");
};
